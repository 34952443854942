body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.side-menu .MuiBadge-badge {
  top: 20px;
}

.slide-out {
  right: 0 !important;
}


.hb-vote-widget {
  background: #443737;
  color: #fff;
  text-shadow: 0 0 3px rgba(0,0,0,0.5);
  text-align: center;
  padding:10px;
  font-family: "Work sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.hb-vote-widget a.hb-link {
  color: #fff;
  text-decoration: none;
}
.hb-vote-widget a.hb-link:hover {
  color: #fff;
}

.hb-vote-widget a.hb-vote-btn {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border: 1px solid rgba(255,255,255,0.8);
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: 2px;
}
.hb-vote-widget a.hb-vote-btn-disabled {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border: 1px solid rgba(255,255,255,0.8);
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: 2px;
  opacity: 0.5;
}
.hb-vote-widget a.hb-vote-btn:hover {

  box-shadow: 0 0 8px #000;
}
.hb-vote-widget img {
  height: 30px;
  margin-left: 5px;
  vertical-align: -9px;
}
